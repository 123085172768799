
::v-deep .category-label {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: 4px 6px;
    background-color: rgba(0, 0, 0, 0.1);

    font-size: 12px;
    text-align: center;
}
